<template>
<section class="user--group cards">
    <div
        v-for="group in groups"
        :key="group.id"
        class="card -accent"
    >
        <div class="card__contentWrapper">
                <p
                    class="card__title"
                    v-text="group.name"
                />
                
                <div class="card__cta">
                    <a
                        :href="group.links.results"
                        class="card__ctaLink"
                    >
                        View Assets
                    </a>
                    <span class="arrow"></span>
                </div>
        </div>
        <loading
            v-if="isLoading"
            :is-loading="isLoading"
        />
    </div>
</section>
</template>

<script>
import axios from 'axios';
import endpoints from 'Root/endpoints';
import Loading from 'Components/Loading';

import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    components: {
        Loading
    },
    data: () => ({
        isLoading: true,
    }),
    computed: {
        groups() {
            return this.userStore.getGroups || [];
        }
    },
    mounted() {
        this.fetchGroups();
    },
    methods: {
        fetchGroups() {
            if (this.groups.length > 0) {
                this.isLoading = false;
                return;
            }

            this.isLoading = true;

            axios.get(endpoints.USER_USER_GROUPS_LIST + '?includes=links')
                .then(res => {
                    this.userStore.setGroups(res.data.data);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>
