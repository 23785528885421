<template>
    <div class="moveToWorkspace">
        <drawer>
            <template v-slot:trigger="{ toggleDrawer }">
                <button
                    class="button"
                    @click="toggleDrawer"
                    @keyup.esc="toggleDrawer"
                >
                    Move to Workspace
                    <i class="link--icon --vertical far fa-fw fa-angle-down text-coral" />
                </button>
            </template>

            <template v-slot:drawer>
                <input
                    v-model="workspaceFilter"
                    class=""
                    placeholder="Workspace name"
                >

                <div
                    v-if="workspaceFilter"
                    class="workspace__filter"
                >
                    <span>"{{ workspaceFilter }}"</span>
                </div>

                <ul
                    v-if="filteredWorkspaces.length"
                    class="max-h-80 overflow-y-scroll"
                >
                    <li
                        v-for="workspace in filteredWorkspaces"
                        :key="workspace.id"
                        class="border-slate-alt border-b mb-2 p-2 last:border-b-0 text-sm"
                    >
                        <span>{{ getTruncatedName(workspace) }}</span>
                        <button
                            class="workspace__linkSm"
                            @click.prevent="confirmMoveToWorkspace(workspace)"
                        >
                            Move
                        </button>
                    </li>
                </ul>
            </template>
        </drawer>
        <modal
            :visible="confirmAction"
            :transparent="false"
        >
            <div
                v-if="!isNewWorkspace && selectedWorkspace"
                class="workspace__modal"
            >
                <div class="workspace__text">
                    Move to existing workspace titled <span class="font-bold">"{{ selectedWorkspace.name }}"</span>?
                </div>
                <div class="workspace__modalActions">
                    <button
                        class="button"
                        @click="moveAssetToWorkspace()"
                    >
                        Move
                    </button>
                    <button
                        class="button -outline -blue"
                        @click="confirmAction = false"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Drawer from '@/Drawer';
import Modal from '@/Modal';

import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    components: {
        Drawer,
        Modal,
    },
    props: {
        asset: {
            type: [Array, Object],
            required: true,
        },
        workspaceId: {
            type: [Number, String],
            required: true,
        },
        workspaces: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            workspaceFilter: null,
            confirmAction: false,
            isNewWorkspace: false,
            selectedWorkspace: null,
        };
    },
    computed: {
        filteredWorkspaces() {
            const workspaces = this.workspaces.filter((workspace) => workspace.workspace_id !== this.workspaceId);

            if (this.workspaceFilter) {
                return workspaces.filter(workspace => workspace.name.indexOf(this.workspaceFilter) !== -1);
            }

            return workspaces;
        }
    },
    methods: {
        getTruncatedName(workspace) {
            if (workspace.name.length > 15) {
                return workspace.name.substring(0, 15) + '...';
            }

            return workspace.name;
        },
        confirmMoveToWorkspace(workspace) {
            this.selectedWorkspace = workspace;
            this.isNewWorkspace = false;
            this.confirmAction = true;
        },
        moveAssetToWorkspace() {
            this.confirmAction = true;

            let assetIds = this.asset.map(asset => asset.asset_id)
            this.userStore.moveAssetsToWorkspace(
                assetIds,
                this.workspaceId,
                this.selectedWorkspace.workspaceId
            ).then(response => {
                console.log(response);
                this.$emit('moved');
            }).finally(() => {
                this.selectedWorkspace = null;
                this.confirmAction = false;
                this.workspaceFilter = null;
            });
        },
    }
};
</script>