<template>
    <div class="container assetDetails">
        <div class="asset__view">
            <div class="asset__header">
                <div class="asset__title">
                    <div class="checkbox">
                        <input
                          v-model="isSelected"
                          type="checkbox"
                          value="1"
                          class="checkbox__input asset__checkbox"
                        />

                        <label class="checkbox__label asset__name" v-html="`Asset ${asset.filename}`"></label>
                    </div>
                    <div :class="`asset__status ${getStatusClasses()}`">
                        {{ statusText }}
                    </div>
                </div>
        
                <asset-actions :asset="asset" />
            </div>
        
            <div class="asset__wrapper">
                <div class="row">
                    <div class="column six">
                        <div class="asset__files">
                            <asset-thumbnail
                                :asset="asset"
                                type="assetDetails"
                                image-size="full"
                            />
        
                            <div
                                v-if="usageDescription || instructions"
                                class="asset__download download"
                                :class="{
                                    rights_managed: usageDescription
                                }"
                                :data-rights-managed="usageDescription ? 1 : 0"
                                :data-instructions="instructions ? 1 : 0"
                            >
                                <template v-if="usageDescription">
                                    <div class="asset__usageDescription usage_description">
                                        USAGE DESCRIPTION: {{ usageDescription }}
                                    </div>
                                    <div class="expiration_date">{{ deactivationDate }}</div>
                                </template>

                                <div v-if="instructions" class="asset__additionalInfo additional_info">
                                    <span v-html="instructions"></span>
                                </div>
                            </div>
                        </div>
                        <div class="asset__details">
                            <dl class="assetDetails__metaList">
                                <dt class="assetDetails__metaLabel">File name</dt>
                                <dd class="assetDetails__metaValue">
                                    {{ filename }}
                                    <a
                                        v-if="isAdmin"
                                        :href="asset.links.edit"
                                        class="link -underlined"
                                    >
                                        Edit Asset
                                    </a>
                                    <p v-if="oldFilename">
                                        <br>
                                        <small>(Old Filename: {{ oldFilename }})</small>
                                    </p>
                                </dd>
                                <dt class="assetDetails__metaLabel">File Type</dt>
                                <dd class="assetDetails__metaValue">{{ fileType }}</dd>

                                <dt class="assetDetails__metaLabel">File Size</dt>
                                <dd class="assetDetails__metaValue">{{ fileSize }}</dd>

                                <dt class="assetDetails__metaLabel">Height</dt>
                                <dd class="assetDetails__metaValue" v-if="height">{{ height }}px</dd>
                                <dd class="assetDetails__metaValue" v-else>N/A</dd>

                                <dt class="assetDetails__metaLabel">Width</dt>
                                <dd class="assetDetails__metaValue" v-if="width">{{ width }}px</dd>
                                <dd class="assetDetails__metaValue" v-else>N/A</dd>
                            </dl>
                        </div>
                    </div>
        
                    <div class="column six">
                        <div class="asset__details">
                            <dl class="assetDetails__metaList">
                                <template v-if="asset.caption">
                                    <dt class="assetDetails__metaLabel">Caption</dt>
                                    <dd class="assetDetails__metaValue">{{ asset.caption }}</dd>
                                </template>
                                <template v-if="association">
                                    <dt class="assetDetails__metaLabel">Association</dt>
                                    <dd class="assetDetails__metaValue" v-html="association"></dd>
                                </template>
                                <template v-if="description">
                                    <dt class="assetDetails__metaLabel">Description</dt>
                                    <dd class="assetDetails__metaValue">{{ description }}</dd>
                                </template>
                                <template v-if="subject">
                                    <dt class="assetDetails__metaLabel">Subject</dt>
                                    <dd class="assetDetails__metaValue">{{ subject }}</dd>
                                </template>
                                <template v-if="collections">
                                    <dt class="assetDetails__metaLabel">Collections</dt>
                                    <dd class="assetDetails__metaValue">{{ collections }}</dd>
                                </template>
                                <template v-if="destination">
                                    <dt class="assetDetails__metaLabel">Destination</dt>
                                    <dd class="assetDetails__metaValue">{{ destination }}</dd>
                                </template>
                            </dl>
        
                            <dl class="assetDetails__metaList">
                                <dt class="assetDetails__metaLabel">Asset Name</dt>
                                <dd class="assetDetails__metaValue">{{ name }}</dd>

                                <dt class="assetDetails__metaLabel">Asset Type</dt>
                                <dd class="assetDetails__metaValue">{{ assetType }}</dd>
                            </dl>
        
                            <dl class="assetDetails__metaList">
                                <dt class="rightsDocs__title assetDetails__metaLabel">Rights Documents</dt>
                                <dd class="rightsDocs__wrapper assetDetails__metaValue">
                                    <ul v-if="asset.limited_rights || hasRightsDocs" class="rightsDocs">
                                        <li v-for="rightsDoc in rightsDocs" :key="rightsDoc.rights_document_id">
                                            <a
                                                :href="rightsDoc.url"
                                                class="link"
                                                :aria-label="`Rights Document (${rightsDoc.name}) for asset ${asset.asset_id}`"
                                                :alt="rightsDoc.name"
                                                v-text="rightsDoc.name.length > 25 ? rightsDoc.truncated_name : rightsDoc.name"
                                            />
                                        </li>
                                    </ul>
                                    <span v-else>
                                        None
                                    </span>
                                </dd>
                                <dt class="assetDetails__metaLabel">Limited Rights</dt>
                                <dd class="assetDetails__metaValue">{{ asset.limited_rights }}</dd>
                            </dl>

                            <dl class="assetDetails__metaList" v-if="asset.deactivation_reason">
                                <dt class="assetDetails__metaLabel">Reason Deactivated:</dt>
                                <dd class="assetDetails__metaValue">{{ asset.deactivation_reason }}</dd>
                            </dl>

                            <dl class="assetDetails__metaList">
                                <dt class="assetDetails__metaLabel">Created By:</dt>
                                <dd class="assetDetails__metaValue">{{ createdBy }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AssetMixins from '@/Mixins/AssetMixins';
import AssetThumbnail from '@/AssetThumbnail';
import AssetDownloads from '@/AssetDetails/AssetDownloads';
import AssetActions from '@/AssetDetails/AssetActions';

import { find, reject } from 'lodash';

import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    components: {
        AssetThumbnail,
        AssetDownloads,
        AssetActions,
    },
    mixins: [
        AssetMixins,
    ],
    props: {
        isAdmin: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        isSelected: {
            get() {
                return find(this.userStore.selectedAssets, asset => asset.asset_id === this.asset.asset_id);
            },
            set(isSelected) {
                let assets = this.userStore.selectedAssets;

                if (isSelected) {
                    assets.push(this.asset);
                } else {
                    assets = reject(assets, asset => asset.asset_id === this.asset.asset_id);
                }

                this.userStore.setSelectedAssets(assets)
            }
        },
        association() {
            if (this.asset.property) {
                const association = `${this.propertyName} (Property)`;

                if (this.isAdmin) {
                    const reportCard = `<br><a href="/reportcard/${this.asset.property_id}">Photography Report Card</a>`;
                    return `${association} ${reportCard}`;
                }

                return association;
            }

            return null;
        },
    },
    mounted() {
        if (!this.userStore.workspaces.length) {
            this.userStore.fetchWorkspaces();
        }
    },
};
</script>