<template>
    <div>
        <div class="grid collection">
            <div class="text-content">
                <h2 v-text="title" />
                <div v-html="introText" />
            </div>
            <div v-if="count" class="thumbnails">
                <div v-for="t, idx in thumbnails" :class="{ 'first':idx==0, 'thmbnl':true }" :style="background(t.url)">
                </div>
                <div :class="toggleClasses">
                    <div>
                        <span v-text="count" /> assets
                    </div>
                    <div>
                        <a href="#" class="button" v-text="toggleText" @click.prevent="toggleDisplay" />
                    </div>
                </div>
            </div>
            <div v-else class="loading-spinner">
                <i class="fa fa-spin fa-spinner" /> Loading...
            </div>
        </div>
        <section class="assetListing mt-12" v-if="displayCards">
            <lazy-component
                    v-for="(chunk, i) in chunked"
                    class="assetListing__contentRow"
                    :key="i"
                >
                <div
                    v-for="asset in chunk"
                    :key="asset.asset_id"
                    class="assetListing__brandGuide"
                >
                    <asset-card
                        :selected="isSelected(asset)"
                        :asset="asset"
                        class="m-2"
                        @input="updateSelected($event, asset)"
                    />
                </div>
            </lazy-component>
        </section>
    </div>
</template>
<script>
import {
    DOWNLOAD_ASSETS,
} from 'Root/endpoints';

import AssetCard from '@/AssetCard';
import LazyComponent from 'v-lazy-component';
import { useCollectionStore } from 'Stores/CollectionStore';
import { useUserStore } from 'Stores/UserStore';
import { reject, chunk, find, debounce } from 'lodash';

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        content: {
            type: Object,
            default: () => {}
        }
    },
    setup() {
        const collectionStore = useCollectionStore();
        const userStore = useUserStore();

        return {
            collectionStore,
            userStore
        };
    },
    components: {
        AssetCard,
        // AssetCardFull,
        // Carousel,
        LazyComponent,
    },
    data() {
        return {
            displayCards: false
        }
    },
    computed: {
        selectedAssets: {
            get() {
                return this.userStore.selectedAssets;
            },
            set(assets) {
                debounce(this.userStore.setSelectedAssets(assets), 1);
            }
        },
        assets() {
            return this.collectionStore.getCollectionAssets ?? [];
        },
        chunked() {
            return chunk(this.assets, 4);
        },
        collectionIds() {
            let ids = [];
            if (this.content.collections) {
                this.content.collections.map(c => ids.push(c.collection_id));
            }

            return ids;
        },
        count() {
            return this.assets.length;
        },
        introText() {
            return this.content.introText ?? '';
        },
        thumbnails() {
            let thumbnails = [];

            if (this.count) {
                const assets = this.assets.slice(0,5);

                assets.map(a => {
                    const t = {
                        id: a.asset_id,
                        url: a.asset_thumbnail_url,
                        alt: a.caption
                    }

                    thumbnails.push(t);
                })
            }

            return thumbnails;
        },
        toggleClasses() {
            return this.displayCards ? 'display-toggle-block block-open' : 'display-toggle-block block-closed';
        },
        toggleText() {
            return this.displayCards ? 'CLOSE AND HIDE -' : 'OPEN TO SEE MORE +'; // 
        }
    },
    methods: {
        background(url) {
            return 'background-image:url(' + url + ')';
        },
        deselect() {
            this.userStore.removeSelectedAssets();
        },
        downloadAssets() {
            let params = new URLSearchParams();

            this.assets.forEach(asset => {
                params.append('asset_ids[]', asset.asset_id);
            });

            window.location = DOWNLOAD_ASSETS + '?' + params.toString();
        },
        isSelected(asset) {
            return find(this.selectedAssets, asset) ? true : false;
        },
        loadAssets() {
            if (this.collectionIds.length) {
                this.collectionStore.fetchAssets(this.collectionIds);
            }
        },
        toggleDisplay() {
            this.displayCards = !this.displayCards;
        },
        updateSelected(isChecked, asset) {
            if (isChecked) {
                this.selectedAssets.push(asset);
            } else {
                this.selectedAssets = reject(this.selectedAssets, asset);
            }
        },
    },
    watch: {
        'content.collections': {
            handler(newVal) {
                this.loadAssets();
            },
            deep: true
        }
    },
    mounted() {
        this.loadAssets();
    }
}
</script>
<style scoped>
.horizontal-line {
    border-top: 1px solid #b9c1c5;
    margin-top: .6rem;
    padding-top: .6rem;
}
</style>