<template>
<section class="workspaces__cardWrapper -noPaddingTop">
    <div class="workspaces cards">
        <workspace-card
            v-for="workspace in userStore.workspaces"
            :key="workspace.workspace_id"
            :workspace="workspace"
        />
        <loading
            v-if="isLoading"
            :is-loading="isLoading"
        />
    </div>
</section>
</template>

<script>
import WorkspaceCard from '@/WorkspaceCard';
import Loading from 'Components/Loading';

import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    components: {
        WorkspaceCard,
        Loading
    },
    data() {
        return {
            isLoading: true,
        };
    },
    mounted() {
        if (!this.userStore.workspaces.length) {
            this.isLoading = true;
            this.userStore.fetchWorkspaces()
                .then(() => {
                    this.isLoading = false;
                });
        }
    },
};
</script>