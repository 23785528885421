<template>
<div class="selectedAssets">
    <div
        v-if="!groupedBy"
        class="selectedAssets__assets"
    >
        <div
            v-for="asset in selectedAssets"
            :key="asset.asset_id"
            class="selectedAssets__asset"
        >
            <asset-card
                :selected="isSelected(asset)"
                :asset="asset"
                class="m-2"
                @input="updateSelected($event, asset)"
            />
        </div>
    </div>

    <div
        v-if="groupedBy === 'property'"
    >
        <span
            class="inline-block text-xl text-slate-text mb-2 font-semibold mb-2"
        >
            Select the property or brand you would like to edit assets for
        </span>

        <div
            v-for="(group, groupName) in groupedByAssociationAssets"
            :key="groupName"
            class="selectedAssets__cards mb-8"
        >
            <span class="inline-block w-full text-slate-text text-lg">
                <label class="cursor-pointer">
                    <input
                        v-model="subselection"
                        type="radio"
                        :value="group"
                        @change="updateSubselection(groupName)"
                    >
                    {{ groupName }}
                </label>
            </span>

            <div
                v-for="asset in group"
                :key="asset.asset_id"
                class="w-1/2 md:w-1/6"
            >
                <asset-card
                    :selected="isSelected(asset)"
                    :asset="asset"
                    class="m-2"
                    @input="updateSelected($event, asset)"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { find, reject, debounce } from 'lodash';
import AssetCard from '@/AssetCard';

import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    components: {
        AssetCard,
    },
    props: {
        groupBy: {
            required: false,
            default: null,
            type: String,
        },
        subselectionGroup: {
            required: false,
            default: null,
            type: String,
        }
    },
    data() {
        return {
            subselection: [],
            internalGroupBy: null,
        };
    },
    computed: {
        groupedBy: {
            get() {
                return this.groupBy;
            },
        },
        selectedAssets: {
            get() {
                return this.userStore.selectedAssets;
            },
            set(assets) {
                debounce(this.userStore.setSelectedAssets(assets), 1);
            }
        },
        groupedByAssociationAssets() {
            let groups = {};

            this.selectedAssets.forEach(asset => {
                if (asset.property_id) {
                    let groupName = `${asset.property.name} (${asset.property.marsha_code})`;

                    if (!groups[groupName]) {
                        groups[groupName] = [];
                    };

                    groups[groupName].push(asset);
                }

                if (asset.brand_id) {
                    let groupName = `${asset.brand.name} (${asset.brand.brand_id})`;

                    if (!groups[groupName]) {
                        groups[groupName] = [];
                    };

                    groups[groupName].push(asset);
                }

            });

            return groups;
        }
    },
    mounted() {
        let keys = Object.keys(this.groupedByAssociationAssets);

        if (this.subselectionGroup) {
            this.subselection = this.groupedByAssociationAssets[this.subselectionGroup];
            this.$emit('update:subselection', this.subselection);
        }

        if (keys.length === 1) {
            this.subselection = this.groupedByAssociationAssets[keys[0]];
            this.$emit('update:subselection', this.subselection);
        }
    },
    methods: {
        updateSelected(isChecked, asset) {
            if (isChecked) {
                this.selectedAssets.push(asset);
            } else {
                this.selectedAssets = reject(this.selectedAssets, asset);
            }
        },
        deselect() {
            this.userStore.removeSelectedAssets();
        },
        isSelected(asset) {
            return find(this.selectedAssets, asset) ? true : false;
        },
        updateSubselection(groupName) {
            this.$emit('update:subselection', this.groupedByAssociationAssets[groupName]);
            this.$emit('update:subselection-group', groupName);
        }
    },
};
</script>

<style>

</style>
