<template>
<section
    class="assetCollection__wrapper"
>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
        <div
            v-if="totalSelected"
            class="assetCollection"
        >
            <div class="assetCollection__navItems">
                <div class="assetCollection__group">
                    <span :class="['assetCollection__total', {'mr-auto': !totalSelected }]">{{ totalSelected }} Assets Selected</span>

                    <button
                        v-if="totalSelected"
                        class="assetCollection__showSelected"
                        @click="showSelected = !showSelected"
                    >
                        <span v-if="showSelected">Hide</span>
                        <span v-else>Show</span>
                        <!-- <i class="fal fa-angle-double-up"/> -->
                    </button>

                    <button
                        v-if="totalSelected"
                        class="assetCollection__deselect --xsmall"
                        @click.prevent="deselect"
                    >
                        Clear
                    </button>
                </div>

                <div class="assetCollection__actionSelect">
                    <label class="cursor-pointer">
                        What would you like to do? 
                        <select
                            v-model="action"
                            class="assetCollection__select text-slate cursor-pointer"
                        >
                            <option
                                value="download"
                                selected
                            >
                                Download
                                <span class="dropdown__icon"></span>
                            </option>

                            <option
                                value="download-media"
                                selected
                            >
                                Download Only Media
                                <span class="dropdown__icon"></span>
                            </option>

                            <option value="contact_sheet">
                                Contact Sheet
                                <i class="assetCollection--selectArrow far fa-fw fa-angle-down text-white"></i>                            
                            </option>
                            <option value="workspace">
                                Workspace
                                <span class="dropdown__icon"></span>
                            </option>
                        </select>
                    </label>
                </div>

                <div class="assectCollection__actionItems">
                    <div v-if="action == 'download' || action == 'download-media'" class="assetCollection__download">
                        <a
                            href="#"
                            v-if="totalSelected"
                            class="assetCollection__workspaceButton -bold"
                            @click.prevent="downloadAssets"
                        >
                            Download Selected Assets <i class="far fa-fw fa-download" />
                        </a>
                    </div>

                    <div v-if="action === 'contact_sheet'">
                        <a
                            v-if="totalSelected"
                            class="assetCollection__contactSheet"
                            :href="contactSheetUrl"
                        >
                            Create Contact Sheet <i class="fal fa-fw fa-layer-group" />
                        </a>
                    </div>

                    <div
                        v-if="action === 'workspace'"
                        class="assetCollection__workspaceSelect"
                    >
                        <ul
                            v-if="showWorkspaces"
                            class="assetCollection__workspaceList "
                        >
                            <li class="assetCollection__workspaceAction">
                                <a
                                    href="#"
                                    class="assetCollection__workspaceActionButton"
                                    @click.prevent="showWorkspaces = false"
                                >
                                    <span class="sr-only">close workspace list</span><i class="far fa-times" />
                                </a>
                            </li>
                            <li
                                v-for="workspace in filteredWorkspaces"
                                :key="workspace.workspace_id"
                            >
                                <label>
                                    <input
                                        v-model="selectedWorkspaces"
                                        type="checkbox"
                                        :value="workspace"
                                        style="width:auto;"
                                    >
                                    <span v-html="workspace.name"></span>
                                </label>
                            </li>
                            <li v-if="!filteredWorkspaces.length">
                                Create "{{ workspaceFilter }}"
                            </li>
                        </ul>

                        <input
                            class="assetCollection__workspaceInput"
                            v-model="workspaceFilter"
                            type="text"
                            placeholder="Enter workspace"
                            @focus="showWorkspaces = true"
                            @input="showWorkspaces = true"
                            @keyup.esc="showWorkspaces = false"
                        >

                        <a
                            v-if="filteredWorkspaces.length > 0"
                            class="assetCollection__workspaceButton"
                            :disabled="selectedWorkspaces.length < 1"
                            @click.prevent="addAssetsToWorkspaces()"
                        >
                            Add Selected ({{ totalSelected }}) <i class="far fa-fw fa-plus" />
                        </a>

                        <a
                            v-if="filteredWorkspaces.length === 0 && workspaceFilter"
                            class="assetCollection__workspaceButton"
                            @click.prevent="createNewWorkspace()"
                        >
                            Create and Add Selected ({{ totalSelected }}) <i class="far fa-fw fa-plus" />
                        </a>
                    </div>

                    <div v-if="action == 'share'">
                        <button
                            v-if="totalSelected"
                            class="assetCollection__share"
                        >
                            Share <i class="far fa-fw fa-share-square" />
                        </button>
                    </div>
                </div>
            </div>

            <asset-collection-listing
                v-if="showSelected"
                :group-by="groupListingBy"
            />
        </div>
    </transition>

    <loading
        :is-loading="isLoading"
        :message="loadingMessage"
    />
</section>
</template>

<script>
import {
    DOWNLOAD_ASSETS,
} from 'Root/endpoints';

import {
    SET_SEARCH_IS_LOADING,
    SET_COLLECTION_ACTION,
} from 'Stores/Frontend/mutation-types';

import Loading from '@/Loading';
import { isEqual, reject, debounce } from 'lodash';
import Notification from '@/Notifications/NotificationModel';
import AssetCollectionListing from '@/AssetCollectionListing';

import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    components: {
        Loading,
        AssetCollectionListing,
    },
    data() {
        return {
            showSelected: false,
            showWorkspaces: false,
            selectedWorkspaces: [],
            newWorkspaceName: '',
            isLoading: false,
            loadingMessage: '',
            internalWorkspaceFilter: null,
            workspacesLoaded: false,
            groupListingBy: null,
        };
    },
    computed: {
        action: {
            get() {
                return this.$store.state.collection.action;
            },
            set(action) {
                this.$store.commit(SET_COLLECTION_ACTION, action);
            }
        },
        selectedAssets: {
            get() {
                return this.userStore.selectedAssets;
            },
            set(assets) {
                debounce(this.userStore.setSelectedAssets(assets), 1);
            }
        },
        workspaces: {
            get() {
                return this.userStore.workspaces;
            },
        },
        filteredWorkspaces() {
            if (!this.workspacesLoaded) {
                this.fetchWorkspaces();
            }

            if (!this.workspaceFilter) {
                return this.workspaces;
            }

            return this.workspaces.filter(workspace => workspace.name.toLowerCase().includes(this.workspaceFilter));
        },
        workspaceFilter: {
            get() {
                return this.internalWorkspaceFilter;
            },
            set(value) {
                this.internalWorkspaceFilter = value;
            }
        },
        totalSelected() {
            return this.userStore.selectedAssets.length;
        },
        contactSheetUrl() {
            const params = new URLSearchParams();

            this.selectedAssets.forEach((asset) => {
                params.append('asset_ids[]', asset.asset_id);
            });

            return '/assets/contact-sheet?' + params.toString();
        }
    },
    watch: {
        action: function(value) {
            if (value === 'workspaces' && !this.workspacesLoaded) {
                this.fetchWorkspaces();
            }
            
            this.groupListingBy = null;
        }
    },
    mounted() {
        this.userStore.getSelectedAssets();
    },
    methods: {
        downloadAssets() {
            let params = new URLSearchParams();

            this.selectedAssets.forEach(asset => {
                params.append('asset_ids[]', asset.asset_id);
            });

            if (this.action === 'download-media') {
                params.append('organized_directories', false);
                params.append('media_only', true);
            }

            this.$store.commit(SET_SEARCH_IS_LOADING, true);
            window.location = DOWNLOAD_ASSETS + '?' + params.toString();
            this.$store.commit(SET_SEARCH_IS_LOADING, false);
        },
        deselect() {
            this.userStore.removeSelectedAssets();
        },
        updateSelected(isChecked, asset) {
            if (isChecked) {
                this.selectedAssets.push(asset);
            } else {
                this.selectedAssets = reject(this.selectedAssets, asset);
            }
        },
        selectAll(event) {
            if (event.originalTarget.checked) {
                this.selectedAssets.push(...this.assets);
            } else {
                this.selectedAssets = differenceWith(this.selectedAssets, this.assets, isEqual);
            }
        },
        isSelected(asset) {

            let selected = this.selectedAssets.filter(selectedAsset => {
                return selectedAsset.asset_id === asset.asset_id;
            });

            if (selected) {
                return true;
            }

            return false;
        },
        fetchWorkspaces() {
            if (!this.userStore.workspaces.length) {
                this.isLoading = true;
                this.loadingMessage = 'Loading Workspaces';

                this.userStore.fetchWorkspaces()
                    .then(() => {
                        this.isLoading = false;
                        this.workspaceLoaded = true;
                    });

            } else {

            }
        },
        addAssetsToWorkspaces() {
            this.isLoading = true;

            let assetIds = this.selectedAssets.map(asset => asset.asset_id);

            this.selectedWorkspaces.forEach(workspace => {
                this.loadingMessage = `Adding assets to ${workspace.name}`;
                this.userStore.addAssetsToWorkspace(
                    workspace.workspace_id,
                    assetIds
                )
                .then(() => {
                    this.isLoading = false;
                    this.showWorkspaces = false;
                    this.$store.dispatch(
                        'addNotification',
                        new Notification('Added assets to workspace'),
                        { root: true }
                    );
                })
                .catch(error => {
                    dispatch(
                        'addNotification',
                        new Notification(error.response.data.message, 'danger'),
                        { root: true }
                    );
                });
            });
        },
        async createNewWorkspace() {
            if (!this.workspaceFilter) {
                return false;
            }

            this.loadingMessage = `Creating new workspace "${this.workspaceFilter}"`;
            this.isLoading = true;
            this.userStore.createWorkspace(
                this.workspaceFilter,
                this.selectedAssets.map(asset => asset.asset_id),
            )
                .then(() => {
                    this.$store.dispatch('addNotification', new Notification(`Created workspace "${this.workspaceFilter}"`));
                    this.fetchWorkspaces();
                })
                .catch(error => {
                    dispatch(
                        'addNotification',
                        new Notification(error.response.data.message, 'danger'),
                        { root: true }
                    );
                })
                .finally(() => {
                    this.isLoading = false;
                    this.showWorkspaces = false;
                    this.workspaceFilter = null;
                    this.loadingMessage = null;
                });

        },
    }
};
</script>

<style>

</style>
