<template>
  <div class="news">
    <div class="news__cards cards">
        <template v-if="loading">
          <div
            v-for="i in pagination.per_page"
            class="card placeholder-card"
          >
            <span>Loading...</span>
          </div>
        </template>
        <template v-else>
          <div
            v-for="article in articles"
            :key="article.news_id"
            class="card"
            >
              <div v-if="article.file" class="card__imageWrapper">
                <img :src="`https://mvwassetlibrary.com${article.file}`" class="card__image"/>
              </div>
            
              <div class="card__contentWrapper">
                  <div v-if="article.title" class="card__title">
                    {{ article.title }}
                  </div>

                  <p v-html="article.blurb" class="card__title"></p>

                  <div class="card__meta">
                    {{ article.date_created_month }}
                    <span>{{ article.date_created_year }}</span>
                  </div>

                  <div class="card__cta">
                    <p class="card__ctaText">View All</p>
                    <span class="arrow"></span>
                  </div>
              </div>
          </div>
        </template>
    </div>

    <div class="searchResults__controls">
      <div class="searchResults__info">
        <span class="searchResults__resultsNumber">
          <span v-text="pagination.total"></span> total news items
        </span>
        <div class="searchResults__pagination">
          <paginate
                v-if="pagination.total_pages"
                container-class="pagination"
                v-model="pagination.current_page"
                :page-count="pagination.total_pages"
                :click-handler="fetchNewsArticles"
                :page-range="5"
                page-class="pagination__item"
                active-class="-active"
                disabled-class="-disabled"
                page-link-class="pagination__link"
                break-view-class="pagination__breakView"
                prev-text="<i class='pagination__icon fa fa-chevron-left' />"
                next-text="<i class='pagination__icon fa fa-chevron-right' />"
            >
            <template v-slot:breakViewContent>
                <div class="">
                    <span>...</span>
                </div>
            </template>
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';
import axios from 'axios';
export default {
    components: {
        Paginate
    },
    data: () => ({
        loading: false,
        articles: [],
        pagination: {
            total: 0,
            count: 0,
            per_page: 6,
            current_page: 1,
            total_pages: 0,
            links: { next: null, prev: null }
        },
    }),
    mounted() {
        this.initNewsArticles();
        window.addEventListener("popstate", this.initNewsArticles, false);
    },
    beforeDestroy () {
        window.removeEventListener("popstate", this.initNewsArticles);
    },
    methods: {
        initNewsArticles() {
            let np = this.$route.query.np ?? 1;
            
            this.pagination.current_page = parseInt(np);
            this.fetchNewsArticles(np);
        },
        fetchNewsArticles(page) {
            this.loading = true;
            axios.get(`/api/news?limit=${this.pagination.per_page}&page=${page}`)
            .then((res) => {
                const { data, meta } = res.data;
                this.articles = data;
               
                this.pagination.total = meta.pagination.total;
                this.pagination.count = meta.pagination.count;
                this.pagination.per_page = meta.pagination.per_page;
                this.pagination.current_page = meta.pagination.current_page;
                this.pagination.total_pages = meta.pagination.total_pages;
                this.pagination.links = meta.pagination.links;

                if (this.$route.query.np != page) {
                    let param = (page > 1) ? {np:page} : {};
                    this.$router.push({name: 'news-list', query: param});
                }
            })
            .catch((err) => {
                this.error = 'Could not fetch articles.';
                this.pagination.current_page = this.$route.query.np;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        changePage(page) {
            this.$store.commit(SET_SEARCH_PAGE, page);
            this.$store.dispatch('search');

            let assetSearch = document.querySelector('.assetSearch');

            if (assetSearch) {
                assetSearch.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
        showGoToPage() {
            this.$nextTick(() => {
                this.$refs.goToPageInput.focus();
            });
        }
    }
};
</script>