import { defineStore } from 'pinia';

export const useWorkspaceStore = defineStore('WorkspaceStore', {
    state: () => {
        return {
            selectedAssets: [],
        }
    },
    actions: {
        setSelectedAssets(assets) {
            this.selectedAssets = assets;
        },
    },
});