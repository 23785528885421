<template>
<section class="torso">
    <div class="torso__container container">
    
        <tabs
            :default-tab="startTab"
            :push-state="true"
        >
            <template v-slot:links="{ activeTab, setTab}">
                <nav class="tabs__list">
                    <button
                        :class="['tabs__tab', {'-active': activeTab === 'news'}]"
                        data-tab="news"
                        @click="setTab"
                    >
                        News
                    </button>

                    <button
                        :class="['tabs__tab', {'-active': activeTab === 'workspaces'}]"
                        data-tab="workspaces"
                        @click="setTab"
                    >
                        My Workspaces
                    </button>

                    <button
                        :class="['tabs__tab', {'-active': activeTab === 'groups'}]"
                        data-tab="groups"
                        @click="setTab"
                    >
                        My Groups
                    </button>

                    <button
                        :class="['tabs__tab', {'-active': activeTab === 'guides'}]"
                        data-tab="guides"
                        @click="setTab"
                        v-if="countBrandGuides"
                    >
                        Brand Guides
                    </button>
                </nav>
            </template>

            <template v-slot:content="{ activeTab }">
                <div class="torso">
                    <div v-if="activeTab === 'news'">
                        <div class="torso__heading">
                            <p>
                                The newest assets added to the library.
                            </p>
                        </div>
                        
                        <news-list></news-list>
                    </div>

                    <div v-if="activeTab === 'workspaces'">
                        <div class="torso__heading">
                            <p>
                                Workspaces allow you to create groupings of assets that you can easily access, share and edit at anytime.
                            </p>
                        </div>

                        <user-workspaces-list />
                    </div>

                    <div v-if="activeTab === 'groups'">
                        <div class="torso__heading">
                            <p>
                                Quickly access saved assets by groups
                            </p>
                        </div>
                        <user-groups-list />
                    </div>

                    <div v-if="activeTab === 'guides' && countBrandGuides">
                        <div class="torso__heading">
                            <p>
                                Access our Brand Guides
                            </p>
                        </div>
                        <user-brand-guides />
                    </div>
                </div>
            </template>
        </tabs>
    </div>
</section>
</template>

<script>
import Tabs from '@/Tabs';
import UserBrandGuides from '@/UserBrandGuides';
import UserGroupsList from '@/UserGroupsList';
import UserWorkspacesList from '@/UserWorkspacesList';
import NewsList from '@/NewsList';
import endpoints from 'Root/endpoints';
import store from 'Stores/Frontend';
import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    store,
    components: {
        NewsList,
        Tabs,
        UserBrandGuides,
        UserGroupsList,
        UserWorkspacesList,
    },
    props: {
        startTab: {
            default: 'news',
            type: String,
            required: false,
        }
    },
    data() {
        return {
            endpoints,
            isLoading: false,
        };
    },
    async mounted() {
        await this.userStore.fetchBrandGuides();
    },
    computed: {
        countBrandGuides() {
            if (this.userStore.getBrandGuides.meta) {
                return this.userStore.getBrandGuides.meta.pagination.total;
            }
            return false;
        }
    },
};
</script>
