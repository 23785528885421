import {
    get,
    set,
    upperCase
} from 'lodash';
//import asset from 'Root/Stores/Frontend/Modules/asset';
import { useAssetStore } from 'Stores/AssetStore';

let AssetMixins = {
    props: {
        asset: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            newComment: null,
        };
    },
    methods: {
        assetStore() {
            return useAssetStore();
        },
        getStatusClasses() {
            let classes = [
                'pill',
                '-rounded',
            ];

            if (this.asset.status === 'active') {
                classes.push('--success');
            }

            if (this.asset.status === 'revisions_needed') {
                classes.push('--yellow');
            }

            if (this.asset.status === 'pending') {
                classes.push('--pending');
            }

            if (this.asset.status === 'deactivated') {
                classes.push('--danger');
            }

            if (this.asset.status === 'inactive') {
                classes.push('--yellow');
            }

            return classes.join(' ');
        },
        toggleApiVisibility() {
            this.assetStore.setApiVisibility(this.asset.asset_id, this.asset.visible_to_api);
        }
    },
    computed: {
        statusText() {
            return upperCase(this.asset.status);
        },
        formattedDateCreated() {
            const date = new Date(this.asset.date_created.replace(/-/g, '/'));
            const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date).slice(0, 3);
            const day = date.getDate();
            const year = date.getFullYear();

            return `${month}. ${day}, ${year}`;
        },
        formattedDateUpdated() {
            const date = new Date(this.asset.last_modified.replace(/-/g, '/'));
            const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date).slice(0, 3);
            const day = date.getDate();
            const year = date.getFullYear();

            return `${month}. ${day}, ${year}`;
        },
        getAssetType() {
            return this.asset.asset_type ? this.asset.asset_type.name : null;
        },
        assetType() {
            return this.getAssetType;
        },
        assetTypeId: {
            set(id) {
                this.asset.asset_type_id = id;
            },
            get() {
                return this.asset.asset_type_id;
            }
        },
        brand() {
            return this.asset.brand ? this.asset.brand.name : null;
        },
        color() {
            return this.asset.color_id;
        },
        subject() {
            return this.asset.subject ? this.asset.subject.name : 'N/A';
        },
        subjectId: {
            set(id) {
                this.asset.subject_id = id;
            },
            get() {
                return this.asset.subject_id;
            }
        },
        caption() {
            return this.asset.caption ? this.asset.caption : 'N/A';
        },
        description: {
            set(description) {
                this.asset.description = description;
            },
            get() {
                return this.asset.description ? this.asset.description : 'N/A';
            }
        },
        extension() {
            return this.asset.extension ? upperCase(this.asset.extension) : 'N/A';
        },
        name() {
            return this.asset.name ? this.asset.name : 'N/A';
        },
        files() {
            return this.asset.files || [];
        },
        filename() {
            return this.asset.filename ? this.asset.filename : 'N/A';
        },
        oldFilename() {
            return this.asset.old_filename ? this.asset.old_filename : null;
        },
        fileSize() {
            return this.asset.high_file_size ? this.asset.high_file_size : 'N/A';
        },
        fileType() {
            return this.asset.file_type ? this.asset.file_type.name : 'N/A';
        },
        width() {
            let width = null;

            if (this.asset.files) {

                this.asset.files.forEach(file=> {
                    if (file.type === 'high') {
                        width = file.width;
                    }
                });
            }

            return width;
        },
        height() {
            let height = null;

            if (this.asset.files) {

                this.asset.files.forEach(file => {
                    if (file.type === 'high') {
                        height = file.height;
                    }
                });
            }

            return height;
        },
        dimensions() {
            if (this.width && this.height) {
                return `${this.width}x${this.height}`;
            }

            return 'N/A';
        },
        createdBy() {
            return this.asset.created_by;
        },
        collections() {
            let names = [];
            if (this.asset.collection) {
                this.asset.collection.map(c => names.push(c.name));
            }
            return names.length ? names.join(', ') : 'N/A';
        },
        destination() {
            return this.asset.destination ? this.asset.destination.name : 'N/A'
        },
        fromMvwal() {
            return this.asset.vistana_asset_id ? true : false;
        },
        mvwalLink() {
            if (!this.asset.links) {
                return null;
            }

            if (this.fromMvwal && this.asset.links) {
                if (this.asset.links.mvwal) {
                    return this.asset.links.mvwal;
                }
            }

            return null;
        },
        fromMdam() {
            return this.asset.mdam_id ? true : false;
        },
        mdamLink() {
            if (!this.asset.links) {
                return null;
            }

            if (this.fromMdam && this.asset.links) {
                if (this.asset.links.mdam) {
                    return this.asset.links.mdam;
                }
            }

            return null;
        },
        usageDescription() {
            return this.asset.usage_description;
        },
        rightsDocs() {
            if (this.asset['rights-documents']) {
                return this.asset['rights-documents'];
            }

            return [];
        },
        hasRightsDocs() {
            return this.rightsDocs.length > 0;
        },
        templateType() {
            return this.asset.template_type ? this.asset.template_type.name : 'N/A';
        },
        templateNumber() {
            return this.asset.template_number;
        },
        keywords: {
            get() {
                return this.asset.keywords ? this.asset.keywords.join(', ') : null;
            },
            set(keywords) {
                this.asset.keywords = keywords.split(', ');
            }
        },
        reportCardGrade: {
            get() {
                return this.asset.report_card_grade;
            },
            set(grade) {
                this.asset.report_card_grade = grade;
            }
        },
        reportCardNotes() {
            return this.asset.report_card_notes;
        },
        metadata: {
            get() {
                return this.asset.metadata;
            },
            set(metadata) {
                this.asset.metadata = metadata;
            }
        },
        marketingCaption: {
            set(caption) {
                this.asset.marketing_caption = caption;
            },
            get() {
                return this.asset.marketing_caption;
            }
        },
        instructions: {
            set(instructions) {
                this.asset.instructions = instructions;
            },
            get() {
                return this.asset.instructions;
            }
        },
        comments: {
            get() {
                return this.asset.comments ? this.asset.comments : null;
            },
            set(comment) {
                if (this.asset.comments) {
                    //
                }
            }
        },
        historyNotes() {
            return this.asset.asset_history_notes ? this.asset.asset_history_notes : null;
        },
        elevateInGallery: {
            get() {
                return this.asset.elevate_in_gallery;
            },
            set(value) {
                this.asset.elevate_in_gallery = value;
            }
        },
        travelAdvisorOnly: {
            get() {
                return this.asset.travel_advisor_only;
            },
            set(value) {
                this.asset.travel_advisor_only = value;
            }
        },
        hwsImageName: {
            get() {
                return this.asset.hws_image_name;
            },
            set(value) {
                this.asset.hws_image_name = value;
            }
        },
        isPrimaryPropertyImage: {
            get() {
                return this.asset.is_primary_property_image;
            },
            set(value) {
                this.asset.is_primary_property_image = value;
            }
        },
        visibleToApi: {
            get() {
                return this.asset.visible_to_api;
            },
            set(value) {
                this.asset.visible_to_api = value;
            }
        },
        gdsRoomTypeCodes: {
            set(codes) {
                this.asset.gds_room_type_codes = codes;
            },
            get() {
                return this.asset.gds_room_type_codes;
            }
        },
        ersRoomTypeCodes: {
            set(codes) {
                this.asset.ers_room_type_codes = codes;
            },
            get() {
                return this.asset.ers_room_type_codes;
            }
        },
        poolCodes: {
            get() {
                return this.asset.pool_codes;
            }
        },
        deactivationDate: {
            set(date) {
                this.asset.deactivation_date = date;
            },
            get() {
                return this.asset.deactivation_date;
            },
        },
        documents: {
            get() {
                if (this.asset['rights-documents']) {
                    return this.asset['rights-documents'];
                }

                return [];
            }
        },
        brandName() {
          if (
            this.asset.association === 'property' &&
            this.asset.property &&
            this.asset.property.brand
          ) {
            return this.asset.property.brand.name;
          }

          if (this.asset.brand) {
            return this.asset.brand.name
          }

          return null;
        },
        propertyName() {
          if (this.asset.property) {
            return this.asset.property.name
          }

          return null
        },
    },
    mounted() {
        if (!this.assetStore.asset) {
            if (window.assetId) {
                this.assetStore.getAsset(window.assetId);
            }
        }
    }
};

export default AssetMixins;
