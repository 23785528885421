<template>
<section>
    <div>
        <loading
            v-if="isLoading"
            :is-loading="isLoading"
        />
        <div class="news">
            <div class="news__cards cards">
                <template v-if="isLoading">
                    <div
                        v-for="i in pagination.count"
                        class="card placeholder-card"
                    >
                        <span>Loading...</span>
                    </div>
                </template>
                <template v-else>
                    <div
                        v-for="guide in brandGuides"
                        :key="guide.id"
                        class="card"
                        >
                        <div v-if="guide.bg_image" class="card__imageWrapper">
                            <img :src="guide.bg_image" class="card__image"/>
                        </div>
                        
                        <div class="card__contentWrapper">
                            <div v-if="guide.title" class="card__title">
                                <strong>{{ guide.title }}</strong>
                            </div>
                            <div 
                                class="card__title subtitle"
                                v-text="guide.subtitle" 
                            />

                            <div 
                                class="card__meta" 
                                v-text="dateFormat(guide.date_created)"
                            />

                            <div class="card__cta">
                                <p class="card__ctaText">
                                    <a :href="guide.view_url">
                                        View Brand Guide <span class="arrow"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="searchResults__controls">
            <div class="searchResults__info">
                <span class="searchResults__resultsNumber">
                    <span v-text="pagination.total"></span> total items
                </span>
                <div class="searchResults__pagination">
                    <paginate
                        v-if="pagination.total_pages"
                        container-class="pagination"
                        v-model="pagination.current_page"
                        :page-count="pagination.total_pages"
                        :click-handler="fetchBrandGuides"
                        :page-range="5"
                        page-class="pagination__item"
                        active-class="-active"
                        disabled-class="-disabled"
                        page-link-class="pagination__link"
                        break-view-class="pagination__breakView"
                        prev-text="<i class='pagination__icon fa fa-chevron-left' />"
                        next-text="<i class='pagination__icon fa fa-chevron-right' />"
                    >
                        <template v-slot:breakViewContent>
                            <div class="">
                                <span>...</span>
                            </div>
                        </template>
                    </paginate>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Loading from 'Components/Loading';
import Paginate from 'vuejs-paginate';
import moment from 'moment/moment';

import { useUserStore } from 'Stores/UserStore';

export default {
    setup() {
        const userStore = useUserStore();

        return {
            userStore
        }
    },
    components: {
        Loading,
        Paginate
    },
    data: () => ({
        isLoading: true,
        pagination: {
            total: 0,
            count: 0,
            per_page: 6,
            current_page: 1,
            total_pages: 0,
            links: { next: null, prev: null }
        },
    }),
    computed: {
        brandGuides() {
            return this.userStore.getBrandGuides.data || [];
        },
        bgMeta() {
            return this.userStore.getBrandGuides.meta.pagination || [];
        },
    },
    mounted() {
        this.loadContent();
        window.addEventListener("popstate", this.loadContent, false);
    },
    beforeDestroy () {
        window.removeEventListener("popstate", this.loadContent);
    },
    methods: {
        loadContent() {
            let page = this.$route.query.bgp ?? 1;
            
            this.pagination.current_page = parseInt(page);
            this.fetchBrandGuides(this.pagination.current_page);
        },
        async fetchBrandGuides(page) {
            await this.userStore.fetchBrandGuides(this.pagination.per_page, page);

            this.pagination.total = this.bgMeta.total;
            this.pagination.count = this.bgMeta.count;
            this.pagination.per_page = this.bgMeta.per_page;
            this.pagination.current_page = this.bgMeta.current_page;
            this.pagination.total_pages = this.bgMeta.total_pages;
            this.pagination.links = this.bgMeta.links;

            if (this.$route.query.bgp != page) {
                let param = (page > 1) ? {bgp:page} : {};
                this.$router.push({name: 'brand-guide-list', query: param});
            }

            this.isLoading = false;
        },
        dateFormat(date) {
            return moment(date).format('MMMM Do YYYY');
        }
    }
};
</script>

<style scoped>
    .card__title {
        margin-bottom:.4rem;
    }
    .subtitle {
        color: #999;
        font-size:.95em;
    }
</style>
