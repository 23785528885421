export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_SYSTEM_MESSAGES = 'SET_SYSTEM_MESSAGES';
export const SET_SYSTEM_MESSAGES_META = 'SET_SYSTEM_MESSAGES_META';
export const SET_MAIN_NAV_IS_OPEN = 'SET_MAIN_NAV_IS_OPEN';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_COLLECTION_ACTION = 'SET_COLLECTION_ACTION';

// Search Stuff
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_IS_LOADING = 'SET_SEARCH_IS_LOADING';
export const SET_SEARCH_HAS_SEARCHED = 'SET_SEARCH_HAS_SEARCHED';
export const SET_SEARCH_META = 'SET_SEARCH_META';
export const SET_SEARCH_ASSET_TYPES = 'SET_SEARCH_ASSET_TYPES';
export const SET_SEARCH_BRANDS = 'SET_SEARCH_BRANDS';
export const SET_SEARCH_CITIES = 'SET_SEARCH_CITIES';
export const SET_SEARCH_COLLECTIONS = 'SET_SEARCH_COLLECTIONS';
export const SET_SEARCH_DATE_CREATED_END = 'SET_SEARCH_DATE_CREATED_END';
export const SET_SEARCH_DATE_CREATED_START = 'SET_SEARCH_DATE_CREATED_START';
export const SET_SEARCH_DESTINATIONS = 'SET_SEARCH_DESTINATIONS';
export const SET_SEARCH_DESTINATION_CONTROLS = 'SET_SEARCH_DESTINATION_CONTROLS';
export const SET_SEARCH_DIVISIONS = 'SET_SEARCH_DIVISIONS';
export const SET_SEARCH_FROM_MDAM = 'SET_SEARCH_FROM_MDAM';
export const SET_SEARCH_GROUPS = 'SET_SEARCH_GROUPS';
export const SET_SEARCH_HIDDEN_FROM_SEARCH = 'SET_SEARCH_HIDDEN_FROM_SEARCH';
export const SET_SEARCH_IS_OFFER = 'SET_SEARCH_IS_OFFER';
export const SET_SEARCH_IS_STOCK = 'SET_SEARCH_IS_STOCK';
export const SET_SEARCH_KEYWORDS = 'SET_SEARCH_KEYWORDS';
export const SET_SEARCH_LAST_MODIFIED_END = 'SET_SEARCH_LAST_MODIFIED_END';
export const SET_SEARCH_LAST_MODIFIED_START = 'SET_SEARCH_LAST_MODIFIED_START';
export const SET_SEARCH_PROPERTIES = 'SET_SEARCH_MARSHA_CODES';
export const SET_SEARCH_PROPERTY_IDS = 'SET_SEARCH_PROPERTY_IDS';
export const SET_SEARCH_ORDER_BY = 'SET_SEARCH_ORDER_BY';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_SEARCH_STATES = 'SET_SEARCH_STATES';
export const SET_SEARCH_STATES_PROVINCES = 'SET_SEARCH_STATES_PROVINCES';
export const SET_SEARCH_STATUS = 'SET_SEARCH_STATUS';
export const SET_SEARCH_SUBJECTS = 'SET_SEARCH_SUBJECTS';
export const SET_SEARCH_RECENT_SEARCH_ID = 'SET_SEARCH_RECENT_SEARCH_ID';
export const SET_SEARCH_HAS_THUMBNAILS = 'SET_SEARCH_HAS_THUMBNAILS';
export const SET_SEARCH_ASSETS = 'SET_SEARCH_ASSETS';
export const SET_SEARCH_LIMIT = 'SET_SEARCH_LIMIT';
export const SET_SEARCH_PAGE = 'SET_SEARCH_PAGE';
export const SET_SEARCH_BRAND_ONLY = 'SET_SEARCH_BRAND_ONLY';
export const SET_SEARCH_PROPERTY_ONLY = 'SET_SEARCH_PROPERTY_ONLY';
export const SET_SEARCH_HAS_RIGHTS_DOCUMENTS = 'SET_SEARCH_HAS_RIGHTS_DOCUMENTS';
export const SET_SEARCH_TEMPLATE_TYPES = 'SET_SEARCH_TEMPLATE_TYPES';
export const SET_SEARCH_PRIMARY_PROPERTY_IMAGE = 'SET_SEARCH_PRIMARY_PROPERTY_IMAGE';
export const SET_SEARCH_ELEVATED_IN_GALLERY = 'SET_SEARCH_ELEVATED_IN_GALLERY';
export const SET_SEARCH_TRAVEL_ADVISOR_ONLY = 'SET_SEARCH_TRAVEL_ADVISOR_ONLY';
export const SET_SEARCH_LIMITED_RIGHTS = 'SET_SEARCH_LIMITED_RIGHTS';
export const SET_SEARCH_RIGHTS_MANAGED = 'SET_SEARCH_RIGHTS_MANAGED';
export const SET_SEARCH_CREATED_BY = 'SET_SEARCH_CREATED_BY';
export const SET_SEARCH_FILE_TYPES = 'SET_SEARCH_FILE_TYPES';
export const SET_SEARCH_COLORS = 'SET_SEARCH_COLORS';
export const SET_SEARCH_TEMPLATE_TYPE_CATEGORIES = 'SET_SEARCH_TEMPLATE_TYPE_CATEGORIES';
export const SET_SEARCH_DATAPOINTS = 'SET_SEARCH_DATAPOINTS';
export const SET_SEARCH_CONTAINERS = 'SET_SEARCH_CONTAINERS';
export const SET_SEARCH_MDAM_IDS = 'SET_SEARCH_MDAM_IDS';
export const SET_SEARCH_FROM_DAC = 'SET_SEARCH_FROM_DAC';
export const SET_SEARCH_DISPLAY_FORMAT = 'SET_SEARCH_DISPLAY_FORMAT';

// User Stuff
// export const SET_USER = 'SET_USER';
// export const SET_USER_SELECTED_ASSETS = 'SET_USER_SELECTED_ASSETS';
// export const SET_USER_ASSOCIATIONS = 'SET_USER_ASSOCIATIONS';
// export const SET_USER_WORKSPACES = 'SET_USER_WORKSPACES';
// export const SET_USER_WORKSPACE_ASSETS = 'SET_USER_WORKSPACE_ASSETS';
// export const SET_USER_GROUPS = 'SET_USER_GROUPS';
// export const SET_USER_WORK_ORDERS = 'SET_USER_WORKORDERS';

// Asset Stuff
// export const SET_ASSET = 'SET_ASSET';

// Available Statues
export const SET_AVAILABLE_STATUSES = 'SET_AVAILABLE_STATUSES';

export default {
    SET_IS_LOADING,
    SET_SYSTEM_MESSAGES,
    SET_SYSTEM_MESSAGES_META,
    SET_MAIN_NAV_IS_OPEN,
    SET_NOTIFICATIONS,
    SET_COLLECTION_ACTION,

    // Search Stuff
    SET_SEARCH_RESULTS,
    SET_SEARCH_IS_LOADING,
    SET_SEARCH_HAS_SEARCHED,
    SET_SEARCH_META,
    SET_SEARCH_ASSET_TYPES,
    SET_SEARCH_BRANDS,
    SET_SEARCH_CITIES,
    SET_SEARCH_CITIES,
    SET_SEARCH_COLLECTIONS,
    SET_SEARCH_DATE_CREATED_END,
    SET_SEARCH_DATE_CREATED_START,
    SET_SEARCH_DESTINATIONS,
    SET_SEARCH_DESTINATION_CONTROLS,
    SET_SEARCH_DIVISIONS,
    SET_SEARCH_FROM_MDAM,
    SET_SEARCH_GROUPS,
    SET_SEARCH_HIDDEN_FROM_SEARCH,
    SET_SEARCH_IS_OFFER,
    SET_SEARCH_IS_STOCK,
    SET_SEARCH_KEYWORDS,
    SET_SEARCH_LAST_MODIFIED_END,
    SET_SEARCH_LAST_MODIFIED_START,
    SET_SEARCH_PROPERTIES,
    SET_SEARCH_PROPERTY_IDS,
    SET_SEARCH_ORDER_BY,
    SET_SEARCH_QUERY,
    SET_SEARCH_STATES_PROVINCES,
    SET_SEARCH_STATES,
    SET_SEARCH_STATUS,
    SET_SEARCH_SUBJECTS,
    SET_SEARCH_RECENT_SEARCH_ID,
    SET_SEARCH_HAS_THUMBNAILS,
    SET_SEARCH_ASSETS,
    SET_SEARCH_LIMIT,
    SET_SEARCH_PAGE,
    SET_SEARCH_BRAND_ONLY,
    SET_SEARCH_PROPERTY_ONLY,
    SET_SEARCH_HAS_RIGHTS_DOCUMENTS,
    SET_SEARCH_TEMPLATE_TYPES,
    SET_SEARCH_TEMPLATE_TYPE_CATEGORIES,
    SET_SEARCH_PRIMARY_PROPERTY_IMAGE,
    SET_SEARCH_ELEVATED_IN_GALLERY,
    SET_SEARCH_TRAVEL_ADVISOR_ONLY,
    SET_SEARCH_LIMITED_RIGHTS,
    SET_SEARCH_RIGHTS_MANAGED,
    SET_SEARCH_CREATED_BY,
    SET_SEARCH_FILE_TYPES,
    SET_SEARCH_COLORS,
    SET_SEARCH_DATAPOINTS,
    SET_SEARCH_CONTAINERS,
    SET_SEARCH_MDAM_IDS,
    SET_SEARCH_FROM_DAC,
    SET_SEARCH_DISPLAY_FORMAT,

    // User Stuff
    // SET_USER,
    // SET_USER_ASSOCIATIONS,
    // SET_USER_WORKSPACES,
    // SET_USER_WORKSPACE_ASSETS,
    // SET_USER_GROUPS,
    // SET_USER_WORK_ORDERS,
    // SET_USER_SELECTED_ASSETS,

    // Asset Stuff
    // SET_ASSET,

    // Available Statuses
    SET_AVAILABLE_STATUSES,
};
