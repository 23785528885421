import { defineStore } from 'pinia';
import axios from 'axios';

export const useCollectionStore = defineStore('CollectionStore', {
    state: () => {
        return {
            allCollections: [],
            collectionAssets: [],
        }
    },
    getters: {
        getAllCollections: (state) => state.allCollections,
        getCollectionAssets: (state) => state.collectionAssets,
    },
    actions: {
        async fetchCollections() {
            try {
                const response = await axios.get('/api/list-collections');
                this.allCollections = response.data.collections;
            } catch (error) {
                console.error('Failed to fetch collections:', error);
            }
        },
        async fetchAssets(collectionIds) {
            try {
                let params = {collection_ids: collectionIds};                
                const response = await axios.get('/api/list-collection-assets', { params });
                this.collectionAssets = response.data.data;
            } catch (error) {
                console.error('Failed to fetch collection assets:', error);
            }
        },
    }
});