import {
    USER_BRAND_GUIDES_LIST,
    USER_WORKSPACES_LIST,
    USER_WORKSPACES_CREATE,
} from 'Root/endpoints';

import { defineStore } from 'pinia';
import { uniqBy } from 'lodash';
import axios from 'axios';

export const useUserStore = defineStore('UserStore', {
    state: () => {
        return {
            user: null,
            selectedAssets: [],
            associations: [],
            brandGuides: [],
            workspaces: [],
            groups: [],
            workOrders: [],
        }
    },
    getters: {
        getBrandGuides: (state) => state.brandGuides,
        getGroups: (state) => state.groups,
    },
    actions: {
        setUser(user) {
            this.user = user;
        },
        setAssociations(associations) {
            this.associations = associations;
        },
        setGroups(groups) {
            this.groups = groups;
        },
        setWorkOrders(workOrders) {
            this.workOrders = workOrders;
        },
        getSelectedAssets() {
            const selectedAssets = JSON.parse(localStorage.getItem('user.selectedAssets')) || [];
            this.selectedAssets = uniqBy(selectedAssets);
        },
        removeSelectedAssets() {
            localStorage.setItem('user.selectedAssets', JSON.stringify([]));
            this.selectedAssets = uniqBy([]);
        },
        setSelectedAssets(assets) {
            localStorage.setItem('user.selectedAssets', JSON.stringify(assets));
            this.selectedAssets = uniqBy(assets);
        },
        async fetchBrandGuides(pageSize, currentPage) {
            try {
                if (!pageSize) {
                    pageSize = 10;
                }
                if (!currentPage) {
                    currentPage = 1;
                }
    
                let params = {
                    limit:pageSize,
                    page:currentPage
                };
    
                const response = await axios.get(USER_BRAND_GUIDES_LIST, {params});

                this.brandGuides = response.data;

                return response; // Return the promise object so it can be chained
            } catch (error) {
                console.log(error);
            }
        },
        async fetchWorkspaces() {
            try {
                const response = await axios.get(USER_WORKSPACES_LIST + '?includes=thumbnail,links');

                this.workspaces = response.data.data;

                return response; // Return the promise object so it can be chained
            } catch (error) {
                console.log(error);
            }
        },
        async createWorkspace(workspaceName, assetIds) {
            let params = { name:workspaceName, asset_ids:assetIds };

            return axios.post(USER_WORKSPACES_CREATE, params);
        },
        async addAssetsToWorkspace(workspaceId, assetIds) {
            return axios.post(`/api/workspaces/${workspaceId}/assets`, { asset_ids: assetIds });
        },
        async moveAssetsToWorkspace(assetIds, sourceId, targetId) {
            return axios.put(`/api/workspaces/${sourceId}/assets/move`, { asset_ids: assetIds, target_workspace_id: targetId })
        },
    }
});