import { defineStore } from 'pinia';

import {
    ASSET_LIST,
    ASSET_UPDATE
} from 'Root/endpoints';

export const useAssetStore = defineStore('AssetStore', {
    state: () => {
        return {
            asset: null,
        }
    },
    getters: {
    },
    actions: {
        async getAsset(assetId) {
            // commit(SET_IS_LOADING, true, { root: true });
            return axios.get(ASSET_LIST, assetId)
                .then((response) => {
                    this.asset = response.data.data;
                }).catch(error => {
                    // dispatch(
                    //     'setNotification',
                    //     new Notification(error.response.statusText, 'emergency')
                    // );
                }).finally(() => {
                    // commit(SET_IS_LOADING, false, { root: true });
                });
        },
        async newComment(comment) {
            axios.get(ASSET_COMMENT_CREATE, {
                user: state.user.user,
                comment
            }).then(response => {
            });
        },
        async setApiVisibility(assetId, visibleToApi) {
            return axios.post(ASSET_UPDATE + '/' + assetId, { visible_to_api: visibleToApi })
                .then(response => {
                    this.asset = response.data.data;
                    // dispatch(
                    //     'addNotification',
                    //     new Notification(`Updated Asset ${payload.asset_id} API visibility`, 'success'),
                    //     { root: true }
                    // );
                }).catch(error => {
                    // dispatch(
                    //     'addNotification',
                    //     new Notification(error.response.statusText, 'emergency'),
                    //     { root: true }
                    // );
                });
        }
    }
});